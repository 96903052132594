<template>
    <el-container>
        <el-aside width="200px" :class="{ 'el-aside--collapse': isCollapse }">
            <el-menu
                :default-active="defaultActive"
                unique-opened
                menu-trigger="click"
            >
                <template v-for="lev1 in menuData">
                    <el-submenu
                        v-if="lev1.type === 'menu_dir'"
                        :index="lev1.name"
                        :key="lev1.id"
                    >
                        <template slot="title">
                            <i :class="lev1.icon"></i>
                            <span>{{ lev1.title }}</span>
                        </template>
                        <template v-for="lev2 in lev1.children">
                            <el-submenu
                                v-if="lev2.type === 'menu_dir'"
                                :index="lev2.name"
                                :key="lev2.id"
                            >
                                <template slot="title">
                                    <i :class="lev2.icon"></i>
                                    <span>{{ lev2.title }}</span>
                                </template>
                                <template v-for="lev3 in lev2.children">
                                    <el-menu-item
                                        v-if="lev3.type === 'menu'"
                                        :key="lev3.id"
                                        :index="lev3.path"
                                        @click="onSelect(lev3)"
                                    >
                                        <i :class="lev3.icon"></i>
                                        <span slot="title">{{
                                            lev3.title
                                        }}</span>
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                            <el-menu-item
                                v-else-if="lev2.type === 'menu'"
                                :key="lev2.id"
                                :index="lev2.path"
                                @click="onSelect(lev2)"
                            >
                                <i :class="lev2.icon"></i>
                                <span slot="title">{{ lev2.title }}</span>
                            </el-menu-item>
                        </template>
                    </el-submenu>
                    <el-menu-item
                        v-else-if="lev1.type === 'menu'"
                        :key="lev1.id"
                        :index="lev1.path"
                        @click="onSelect(lev1)"
                    >
                        <i :class="lev1.icon"></i>
                        <span slot="title">{{ lev1.title }}</span>
                    </el-menu-item>
                </template>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header height="50px">
                <el-row type="flex" justify="space-between" align="middle">
                    <div
                        class="header-collapse"
                        @click="isCollapse = !isCollapse"
                    >
                        <i class="fa fa-bars"></i>
                    </div>
                    <el-tooltip content="进入全屏">
                        <div
                            v-show="!isFullscreen"
                            class="header-fullscreen"
                            @click="startFullscreen"
                        >
                            <i class="fa fa-expand"></i>
                        </div>
                    </el-tooltip>
                    <el-tooltip content="退出全屏">
                        <div
                            v-show="isFullscreen"
                            class="header-fullscreen"
                            @click="stopFullscreen"
                        >
                            <i class="fa fa-compress"></i>
                        </div>
                    </el-tooltip>
                    <el-tooltip content="退出登录">
                        <div class="header-logout" @click="onLogout">
                            <i class="fa fa-power-off"></i>
                        </div>
                    </el-tooltip>
                    <img
                        @click="toArchive"
                        class="header-avatar"
                        :src="$store.state.avatar"
                    />
                    <div @click="toArchive" class="header-username">
                        {{ $store.state.username }}
                    </div>
                </el-row>
            </el-header>
            <el-main>
			<keep-alive :include="$store.state.keepAlive">
                <router-view></router-view>
				</keep-alive>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import { post } from "@/assets/js/http.js";

function pad(value) {
    return value < 10 ? "0" + value : value;
}

export default {
    name: "view-index",
    data() {
        return {
            date: "",
            menuData: [],
            isCollapse: false,
            defaultActive: "",
            isFullscreen: false
        };
    },
    mounted() {
        this.updateDate();
        // 监听全屏事件
        document.body.addEventListener(
            "fullscreenchange",
            this.onFullscreenChange
        );
        this.$root.$on("update-menu", () => {
            this.getMenuData();
        });
        this.getMenuData();
    },
    beforeDestroy() {
        // 移除全屏事件
        document.body.removeEventListener(
            "fullscreenchange",
            this.onFullscreenChange
        );
    },
    watch: {
        $route() {
            this.updateDefaultActive();
        }
    },
    methods: {
        updateDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const h = date.getHours();
            const m = date.getMinutes();
            const s = date.getSeconds();
            this.date = `${year}-${pad(month)}-${pad(day)} ${pad(h)}:${pad(
                m
            )}:${pad(s)}`;
            setTimeout(() => {
                this.updateDate();
            }, 1000);
        },
        toArchive() {
            this.$router.push("/archive");
        },
        onFullscreenChange() {
            this.isFullscreen = !this.isFullscreen;
        },
        async getMenuData() {
            const data = await post({
                url: "/admin/index/index",
                loading: true
            });
            this.menuData = data.menus;
            this.$store.commit("setAdminInfo", data.adminInfo);
            this.updateDefaultActive();
        },
        updateDefaultActive() {
            this.$nextTick(() => {
                const pathSplit = this.$route.path.split("/");
                this.defaultActive = "/" + pathSplit[1] || "";
            });
        },
        startFullscreen() {
            try {
                document.body.requestFullscreen();
            } catch (err) {
                this.$message.error("切换全屏失败");
            }
        },
        stopFullscreen() {
            document.exitFullscreen();
        },
        onLogout() {
            this.$confirm("确认退出登录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "default"
            })
                .then(async () => {
                    await post({
                        url: "/admin/index/logout",
                        data: {},
                        loading: true
                    });
                    this.$message.success("退出登录成功");
                    this.$store.commit("logout");
                    this.$router.replace("/login");
                })
                .catch(() => {});
        },
        onSelect(item) {
            if (Array.isArray(item.children)) {
                const visit = item.children.find(item => item.path === "visit");
                if (visit) {
                    this.$store.commit(
                        "setButtons",
                        item.children.filter(item => item.path !== "visit")
                    );
                    this.$router.push(item.path);
                } else {
                    this.$message.error("您没有该页面访问权限");
                    this.updateDefaultActive();
                }
            } else {
                this.$message.error("您没有该页面访问权限");
                this.updateDefaultActive();
            }
        }
    }
};
</script>
